var render = function render(){
  var _this = this;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-supplierGeneral"
  }, [_vm.supplier ? _c('v-card', {
    staticClass: "px-5 mx-sm-5 mt-10",
    attrs: {
      "flat": ""
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "col-12 col-md-6 pt-0"
  }, [_c('v-card-title', [_vm._v(_vm._s(_vm.$t('GeneralInformation')))]), _c('v-card-text', [_c('v-form', {
    ref: "supplierForm"
  }, [_vm.supplier ? _c('v-text-field', {
    ref: "supplierNameForm",
    attrs: {
      "label": _vm.$t('name'),
      "rules": [function (value) {
        return !!value.trim() || _this.$t('nameRules');
      }],
      "outlined": "",
      "required": ""
    },
    on: {
      "input": function ($event) {
        _vm.nameModified = true;
      }
    },
    model: {
      value: _vm.supplier.name,
      callback: function ($$v) {
        _vm.$set(_vm.supplier, "name", $$v);
      },
      expression: "supplier.name"
    }
  }) : _vm._e()], 1)], 1), _c('div', {
    staticClass: "pb-4 pl-3"
  }, [_c('v-btn', {
    staticClass: "text-none mr-2",
    attrs: {
      "color": "error",
      "title": _vm.$t('Cancel')
    },
    on: {
      "click": _vm.getSupplier
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-ban ")]), _vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")], 1), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "disabled": !_vm.nameModified,
      "loading": _vm.loading,
      "color": "success",
      "title": _vm.$t('valid')
    },
    on: {
      "click": _vm.modifySupplierName
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t('valid')) + " ")], 1)], 1), _c('v-card-title', [_vm._v(_vm._s(_vm.$t('AvailableToBCMNetwork')))]), _c('v-card-text', [_vm.supplier ? _c('v-switch', {
    attrs: {
      "label": _vm.supplier.public ? _vm.$t('IsAvailableToBCMNetwork') : _vm.$t('IsNotAvailableToBCMNetwork')
    },
    model: {
      value: _vm.supplier.public,
      callback: function ($$v) {
        _vm.$set(_vm.supplier, "public", $$v);
      },
      expression: "supplier.public"
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "pb-4 pl-3"
  }, [_c('v-btn', {
    staticClass: "text-none mr-2",
    attrs: {
      "color": "error"
    },
    on: {
      "click": _vm.getSupplier
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-ban ")]), _vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")], 1), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "loading": _vm.loading,
      "color": "success"
    },
    on: {
      "click": _vm.modifySupplierPublic
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t('valid')) + " ")], 1)], 1), _c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('DocumentConfiguration')) + " ")]), _c('v-card-text', [_c('v-tooltip', {
    attrs: {
      "right": "",
      "content-class": "tooltip-opaque"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('span', _vm._g({}, on), [_c('span', {
          staticClass: "text-subtitle-1"
        }, [_vm._v(" " + _vm._s(_vm.$t('Footer')) + " ")]), _c('v-icon', {
          attrs: {
            "small": "",
            "color": "info"
          }
        }, [_vm._v(" fas fa-question-circle ")])], 1)];
      }
    }], null, false, 1318909529)
  }, [_c('div', [_c('v-img', {
    attrs: {
      "max-width": "800px",
      "src": require("../../../assets/quoteFooter.png"),
      "contain": ""
    }
  })], 1)]), _c('v-textarea', {
    attrs: {
      "rules": _vm.rulesFooter,
      "hint": _vm.$t('WillBeAtEachPageFooter'),
      "persistent-hint": "",
      "rows": "3",
      "auto-grow": "",
      "outlined": ""
    },
    on: {
      "keydown": _vm.verifyCharacter
    },
    model: {
      value: _vm.supplier.quoteConfiguration.footer,
      callback: function ($$v) {
        _vm.$set(_vm.supplier.quoteConfiguration, "footer", $$v);
      },
      expression: "supplier.quoteConfiguration.footer"
    }
  }), _c('div', [_c('v-btn', {
    staticClass: "text-none mr-2",
    attrs: {
      "color": "error"
    },
    on: {
      "click": _vm.getSupplier
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-ban ")]), _vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")], 1), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "loading": _vm.loading,
      "color": "success"
    },
    on: {
      "click": _vm.modifySupplierQuoteConfiguration
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t('Validate')) + " ")], 1)], 1)], 1), _c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('PaymentConfiguration')) + " ")]), _c('v-card-text', [_c('span', {}, [_vm._v(" " + _vm._s(_vm.$t('PaymentConfigurationForBankTransfer')) + " ")]), _c('v-textarea', {
    attrs: {
      "rules": _vm.rulesPaymentConfiguration,
      "rows": "3",
      "auto-grow": "",
      "outlined": ""
    },
    model: {
      value: _vm.supplier.paymentConfiguration,
      callback: function ($$v) {
        _vm.$set(_vm.supplier, "paymentConfiguration", $$v);
      },
      expression: "supplier.paymentConfiguration"
    }
  }), _c('div', [_c('v-btn', {
    staticClass: "text-none mr-2",
    attrs: {
      "color": "error"
    },
    on: {
      "click": _vm.getSupplier
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-ban ")]), _vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")], 1), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "loading": _vm.loading,
      "color": "success"
    },
    on: {
      "click": _vm.modifySupplierPaymentConfiguration
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t('Validate')) + " ")], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "col-12 col-md-6 pt-0"
  }, [_c('v-card-title', [_vm._v(_vm._s(_vm.$t('EditSupplierBillingAddress')))]), _c('v-card-text', {
    staticClass: "pb-0"
  }, [_c('formAddress', {
    ref: "mySupplier",
    attrs: {
      "address": _vm.supplier.address,
      "restricted-country-list": [],
      "is-valid-v-a-t": _vm.isValidVAT,
      "required-company": true,
      "required-zip-code": true,
      "required-phone-number": true,
      "required-v-a-tnumber": true,
      "allow-to-set-not-applicable-v-a-t": true
    },
    on: {
      "modified": function ($event) {
        _vm.addressModified = true;
      },
      "validateVAT": _vm.validateVAT,
      "updated": _vm.updateSupplierAddress
    }
  })], 1), _c('div', {
    staticClass: "pb-4 pl-3"
  }, [_c('v-btn', {
    staticClass: "text-none mr-2",
    attrs: {
      "color": "error",
      "title": _vm.$t('Cancel')
    },
    on: {
      "click": _vm.getSupplier
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-ban ")]), _vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")], 1), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "disabled": !_vm.addressModified,
      "color": "success",
      "title": _vm.$t('valid')
    },
    on: {
      "click": _vm.modifySupplierAddress
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t('valid')) + " ")], 1)], 1)], 1)], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }