<template>
  <div class="page-supplierGeneral">
    <v-card
      v-if="supplier"
      flat
      class="px-5 mx-sm-5 mt-10"
    >
      <v-row>
        <v-col class="col-12 col-md-6 pt-0">
          <v-card-title>{{ $t('GeneralInformation') }}</v-card-title>
          <v-card-text>
            <v-form ref="supplierForm">
              <v-text-field
                v-if="supplier"
                v-model="supplier.name"
                ref="supplierNameForm"
                :label="$t('name')"
                :rules="[(value) => !!value.trim() || this.$t('nameRules')]"
                outlined
                required
                @input="nameModified = true"
              >
              </v-text-field>
            </v-form>
          </v-card-text>
          <div class="pb-4 pl-3">
            <v-btn
              color="error"
              class="text-none mr-2"
              :title="$t('Cancel')"
              @click="getSupplier"
            >
              <v-icon small class="mr-1">
                fas fa-ban
              </v-icon>
              {{ $t('Cancel') }}
            </v-btn>
            <v-btn
              :disabled="!nameModified"
              :loading="loading"
              color="success"
              class="text-none"
              @click="modifySupplierName"
              :title="$t('valid')"
            >
              <v-icon small class="mr-1">
                fas fa-check
              </v-icon>
              {{ $t('valid') }}
            </v-btn>
          </div>
          <v-card-title>{{ $t('AvailableToBCMNetwork') }}</v-card-title>
          <v-card-text>
            <v-switch
              v-if="supplier"
              v-model="supplier.public"
              :label="supplier.public ? $t('IsAvailableToBCMNetwork') : $t('IsNotAvailableToBCMNetwork')"
            >
            </v-switch>
          </v-card-text>
          <div class="pb-4 pl-3">
            <v-btn
              color="error"
              class="text-none mr-2"
              @click="getSupplier"
            >
              <v-icon small class="mr-1">
                fas fa-ban
              </v-icon>
              {{ $t('Cancel') }}
            </v-btn>
            <v-btn
              :loading="loading"
              color="success"
              class="text-none"
              @click="modifySupplierPublic"
            >
              <v-icon small class="mr-1">
                fas fa-check
              </v-icon>
              {{ $t('valid') }}
            </v-btn>
          </div>
          <v-card-title>
            {{ $t('DocumentConfiguration') }}
          </v-card-title>
          <v-card-text>
            <v-tooltip right content-class="tooltip-opaque">
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <span class="text-subtitle-1">
                    {{ $t('Footer') }}
                  </span>
                  <v-icon small color="info">
                    fas fa-question-circle
                  </v-icon>
                </span>
              </template>
              <div>
                <v-img
                  max-width="800px"
                  src="../../../assets/quoteFooter.png"
                  contain
                >
                </v-img>
              </div>
            </v-tooltip>
            <v-textarea
              :rules="rulesFooter"
              :hint="$t('WillBeAtEachPageFooter')"
              persistent-hint
              rows="3"
              auto-grow
              v-model="supplier.quoteConfiguration.footer"
              outlined
              @keydown="verifyCharacter"
            >
            </v-textarea>
            <div>
              <v-btn
                color="error"
                class="text-none mr-2"
                @click="getSupplier"
              >
                <v-icon small class="mr-1">
                  fas fa-ban
                </v-icon>
                {{ $t('Cancel') }}
              </v-btn>
              <v-btn
                :loading="loading"
                color="success"
                class="text-none"
                @click="modifySupplierQuoteConfiguration"
              >
                <v-icon small class="mr-1">
                  fas fa-check
                </v-icon>
                {{ $t('Validate') }}
              </v-btn>
            </div>
          </v-card-text>
          <v-card-title>
            {{ $t('PaymentConfiguration') }}
          </v-card-title>
          <v-card-text>
            <span class="">
              {{ $t('PaymentConfigurationForBankTransfer') }}
            </span>
            <v-textarea
              :rules="rulesPaymentConfiguration"
              rows="3"
              auto-grow
              v-model="supplier.paymentConfiguration"
              outlined
            >
            </v-textarea>
            <div>
              <v-btn
                color="error"
                class="text-none mr-2"
                @click="getSupplier"
              >
                <v-icon small class="mr-1">
                  fas fa-ban
                </v-icon>
                {{ $t('Cancel') }}
              </v-btn>
              <v-btn
                :loading="loading"
                color="success"
                class="text-none"
                @click="modifySupplierPaymentConfiguration"
              >
                <v-icon small class="mr-1">
                  fas fa-check
                </v-icon>
                {{ $t('Validate') }}
              </v-btn>
            </div>
          </v-card-text>
        </v-col>
        <v-col class="col-12 col-md-6 pt-0">
          <v-card-title>{{ $t('EditSupplierBillingAddress') }}</v-card-title>
          <v-card-text class="pb-0">
            <formAddress
              :address="supplier.address"
              ref="mySupplier"
              @modified="addressModified = true"
              :restricted-country-list="[]"
              :is-valid-v-a-t="isValidVAT"
              :required-company="true"
              :required-zip-code="true"
              :required-phone-number="true"
              :required-v-a-tnumber="true"
              :allow-to-set-not-applicable-v-a-t="true"
              @validateVAT="validateVAT"
              @updated="updateSupplierAddress"
            >
            </formAddress>
          </v-card-text>
          <div class="pb-4 pl-3">
            <v-btn
              color="error"
              class="text-none mr-2"
              :title="$t('Cancel')"
              @click="getSupplier"
            >
              <v-icon small class="mr-1">
                fas fa-ban
              </v-icon>
              {{ $t('Cancel') }}
            </v-btn>
            <v-btn
              :disabled="!addressModified"
              color="success"
              class="text-none"
              @click="modifySupplierAddress"
              :title="$t('valid')"
            >
              <v-icon small class="mr-1">
                fas fa-check
              </v-icon>
              {{ $t('valid') }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { EventBus, FormAddress, ApiErrorParser } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageSupplierGeneral.i18n.json');

export default {
  name: 'PageSupplierGeneral',
  components: { FormAddress },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      nameModified: false,
      addressModified: false,
      loading: false,
      supplier: null,
      isValidVAT: false,
      rulesFooter: [
        (v) => { return v.length <= 200 || this.$t('NCharactersMax', {n: 200}); },
      ],
      rulesPaymentConfiguration: [
        (v) => { return v.length <= 1000 || this.$t('NCharactersMax', {n: 1000}); },
      ]
    };
  },
  created() {
    /**
     * GET SUPPLIER
     */
    this.getSupplier();
  },
  methods: {
    /**
     * GET SUPPLIER
     */
    getSupplier() {
      this.$apiInstance
        .getSupplier(this.$route.params.supplierUUID)
        .then(
          (supplierData) => {
            this.supplier = supplierData;
          },
          (error) => {
            /**
             * ERROR GET PRINTER
             */
            ApiErrorParser.parse(error);
          }
        )
        .finally(() => {
          this.nameModified = false;
          this.addressModified = false;
          this.loading = false;
        });
    },
    /**
     * MODIFY SUPPLIER ADDRESS
     */
    modifySupplierAddress() {
      if (this.$refs.mySupplier.validate()) {
        const modifySupplierAddressBody = new this.$BcmModel.ModifyAddressBody(
          this.supplier.address.country,
          this.supplier.address.firstname,
          this.supplier.address.lastname,
          this.supplier.address.company,
          this.supplier.address.streetAddress,
          this.supplier.address.zipCode,
          this.supplier.address.city,
          this.supplier.address.state,
          this.supplier.address.phoneNumber,
          this.supplier.address.VATnumber
        );
        this.$apiInstance
          .modifySupplierAddress(
            this.$route.params.supplierUUID,
            modifySupplierAddressBody
          )
          .then(
            () => {
              this.$notification.notify('SUCCESS',this.$t('SupplierAddressSuccessfullyModified'));
              this.getSupplier();
            },
            (error) => {
              /**
               * ERROR MODIFY PRINTER
               */
              this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
            }
          );
      }
    },
    /**
     * MODIFY SUPPLIER NAME
     */
    modifySupplierName() {
      if (this.$refs.supplierForm.validate()) {
        this.loading = true;
        const modifySupplierNameBody = new this.$BcmModel.ModifySupplierNameBody(
          this.supplier.name
        );
        this.$apiInstance
          .modifySupplierName(
            this.$route.params.supplierUUID,
            modifySupplierNameBody
          )
          .then(
            () => {
              this.$notification.notify('SUCCESS',this.$t('SupplierNameSuccessfullyModified'));
              EventBus.$emit('reloadOrganizationSelector');
            },
            (error) => {
              /**
               * ERROR MODIFY PRINTER
               */
              this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
            }
          )
          .finally(() => {
            this.getSupplier();
          });
      }
    },
    updateSupplierAddress(address) {
      this.supplier.address = address;
    },
    async validateVAT(countryCode, vat) {
      this.isValidVAT = await this.$apiInstance.validateEuropeanVAT(countryCode, vat)
        .then((data) => {
          return true;
        }, (error) => {
          return false;
        });
    },
    modifySupplierPublic() {
      this.loading = true;
      const modifySupplierPublicBody = new this.$BcmModel.ModifySupplierPublicBody(this.supplier.public);
      this.$apiInstance.modifySupplierPublic(
        this.$route.params.supplierUUID,
        modifySupplierPublicBody
      ).then(data => {
        this.supplier = data;
        this.$notification.notify('SUCCESS',this.$t('SupplierInformationUpdated'));
      }, (error) => {
        this.$notification.notify('ERROR', ApiErrorParser.parse(error));
      }).finally(() => {
        this.loading = false;
      });
    },
    modifySupplierQuoteConfiguration() {
      this.loading = true;
      const modifySupplierQuoteConfigurationBody = new this.$BcmModel.ModifySupplierQuoteConfigurationBody(
        this.supplier.quoteConfiguration.footer
      );
      this.$apiInstance.modifySupplierQuoteConfiguration(
        this.$route.params.supplierUUID,
        modifySupplierQuoteConfigurationBody
      ).then(data => {
        this.supplier = data;
        this.$notification.notify('SUCCESS', this.$t('QuoteConfigurationModified'));
      }, (error) => {
        this.$notification.notify('ERROR', ApiErrorParser.parse(error));
      }).finally(() => {
        this.loading = false;
      });
    },
    modifySupplierPaymentConfiguration() {
      this.loading = true;
      const modifySupplierPaymentConfigurationBody = new this.$BcmModel.ModifySupplierPaymentConfigurationBody(
        this.supplier.paymentConfiguration
      );
      this.$apiInstance.modifySupplierPaymentConfiguration(
        this.$route.params.supplierUUID,
        modifySupplierPaymentConfigurationBody
      ).then(data => {
        this.supplier = data;
        this.$notification.notify('SUCCESS', this.$t('PaymentConfigurationModified'));
      }, (error) => {
        this.$notification.notify('ERROR', ApiErrorParser.parse(error));
      }).finally(() => {
        this.loading = false;
      });
    },
    verifyCharacter(event) {
      if(event.key === 'Enter') {
        event.preventDefault();
      }
    }
  },
};
</script>

<style scoped>
  .tooltip-opaque {
    opacity: 1 !important;
  }
</style>